<template>
	<div class='h100 displayFlex flexColumn'>
		<NavbarVue :showBack="true" backgroundColor="#FF9941" color="white">Pending Tasks</NavbarVue>
		<div class='h100 displayFlex flexColumn'>
			<!-- 左侧Notification列表名 -->
			<div class="h10 header">
				<el-input v-model="inputSearch" prefix-icon="el-icon-search">
				</el-input>
			</div>
			<!-- 右侧列表具体内容 -->
			<div class="p30px" style="overflow:auto;">
				<el-table :data="tableData" style="width: 100%;" height="610" @row-click="rowClick" :cell-style="{cursor: 'pointer'}">
					<el-table-column prop="kyc_no" label="KYC ID" width="250">
					</el-table-column>
					<el-table-column prop="client_name_a" label="Client Name">
					</el-table-column>
					<el-table-column prop="create_time" label="Create Date" width="150" />
					<el-table-column prop="creater" label="Creator" />
					<el-table-column prop="status" label="Status" width="150">
						<template slot-scope="scope">
							{{$dictStatus(scope.row.status)}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="PDF" width="150">
						<template slot-scope="scope">
							<img src="../../assets/img/pdf.png" alt="" @click="handleImg(scope.row.kyc_attachment, scope.row.kyc_no)" class="pointer">
						</template>
					</el-table-column>
				</el-table>
				<PaginationVue :pages="pages" listKey="getList" pageKey="pages" @SetPagination="$SetPagination">
				</PaginationVue>
			</div>
		</div>
	</div>
</template>

<script>
import NavbarVue from "../../components/Navbar.vue";
import { GET_username } from '../../utils/localStorage';
import PaginationVue from "../../components/Pagination.vue";
import { getToDoList } from 'api/Home'
export default {
	components: { NavbarVue, PaginationVue },
	data () {
		return {
			tableData: [],
			inputSearch: '',
			// pages分页
			pages: {
				total: 0,
				pageNum: 1,
				pageSize: 10,
			},
			params: {
				agent_code: GET_username(),
				rep_category: this.$store.state.User.Rep.rep_category,
				kyc_no: '',
				client_code: '',
				type: '1'
			},
			timer: null
		};
	},
	computed: {
		ApiData () {
			return { page_number: String(this.pages.pageNum), page_size: String(this.pages.pageSize), ...this.params}
		},
	},
	watch: {
		inputSearch (val) {
			this.params.kyc_no = val
			if (val) {
				this.getList()
			}
		}
	},
	methods: {
		getList () {
			getToDoList(this.ApiData).then(res => {
				this.pages.total = res.content.count
				this.tableData = res.content.to_do_list
				this.tableData.map(data => {
					data.create_time = data.create_time.split(' ')[0]
				})
			})
		},
		handleImg (url, kyc_no) {
			let newUrl = url + '&kycNumber=' + kyc_no
			window.open(newUrl, '_blank')
		},
		rowClick (row, column) {
			if (column && column.label == 'PDF') {
				return;
			} else {
				localStorage.setItem('PDFUrl', row.kyc_attachment)
				this.$router.push('/PendingTasksDetail?kyc_no=' + row.kyc_no + '&creater=' + row.creater)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getList()
		this.timer = setInterval(() => {
      this.getList()
		}, 180000)
	},
	destroyed () {
		clearInterval(this.timer)
	},
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
.header {
	padding: 20px 200px 0 200px;
}
.el-input >>> .el-input__inner {
	height: 52px;
	line-height: 52px;
	border-radius: 25px;
	padding-left: 80px;
}
.el-input >>> .el-input__prefix {
	font-size: 30px;
	top: 10px;
	left: 15px;
	color: #181717;
}
.el-table >>> tr {
	height: 60px;
}
</style>